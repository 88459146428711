(function($) {

    'use strict';

    var CartPreview = function(element, options) {
        this.options = options;
        this.$element = $(element);
        this.$itemCount = this.$element.find('[data-field=item-count]');
        this.$subtotalPrice = this.$element.find('[data-subtotal-price]');
        this.$cartPreviewPopover = this.$element.find('[data-cart-preview-popover]');

        this.init();
    };

    CartPreview.DEFAULTS = {
        count: 0,
        jsonUrl: null,
        loaded: false
    };

    CartPreview.prototype.init = function() {
        var cart = $.cookie('DAFITI-cart');
        if (cart) {
            cart = $.parseJSON(cart);
        } else {
            return false;
        }

        var count = 0;
        $.each(cart, function(index, value) {
            var quantity = value.qt || 1;
            count += quantity;
        });
        this.updateItemCount(count,1);
    };

    CartPreview.prototype.setReload = function(qty) {
        if (typeof qty === 'number') {
            this.options.count = qty;
        }
        this.options.loaded = false;
    };

    CartPreview.prototype.reload = function() {
        this.options.loaded = false;
        this.resetItens();
        this.open();
    };

    CartPreview.prototype.close = function() {
        this.$element.popover('close');
    };

    CartPreview.prototype.open = function() {
        if (this.options.loaded) {
            if (this.options.count > 0) {
                this.$element.popover('open');
            }
        } else {
            this.getJson().success($.proxy(this, "finishJson"));
        }
    };

    CartPreview.prototype.getJson = function() {
        return $.ajax({
            global: false,
            dataType: 'json',
            url: this.options.jsonUrl
        });
    };

    CartPreview.prototype.finishJson = function(data) {
        var number = 0;
        this.options.loaded = true;
        this.dataItems = data.items;
        this.data = data;

        $('[data-cart-preview-loading]').addClass('hide');
        this.$element.find('.cart-preview-wrapper').removeClass('hide');
        this.$element.find('.footer-subtotal').removeClass('hide');

        $.each(this.dataItems, function(key, value) {
            if (value.quantity) {
                number += value.quantity;
            }
        });
        var productCells = this.dataItems.length;

        if ($('.header-full').length < 1) {
            if (productCells > 3)
                productCells = 3;
        } else {
            if (productCells > 20)
                productCells = 20;
        }

        this.updateItemCount(number);
        this.fillFields(productCells);
        this.open();
    };

    CartPreview.prototype.addItemCount = function(number) {
        if (typeof number !== "number") {
            return false;
        }
        if (typeof this.options.count !== "number") {
            this.options.count = 0;
        }
        this.options.count = parseInt(this.options.count) + number;
        this.changeItemCount(this.options.count);
    };

    CartPreview.prototype.removeItemCount = function(number) {
        if (typeof number !== "number") {
            return false;
        }
        this.options.count = parseInt(this.option.count) - number;
        this.changeItemCount(number);
    };

    CartPreview.prototype.updateItemCount = function(number, first) {
        if (typeof number === "undefined") {
            number = this.options.count;
        }
        if (number <= 0) {
            number = "";
        }
        this.options.count = number;
        this.changeItemCount(this.options.count, first);
    };

    CartPreview.prototype.changeItemCount = function(number, first) {
        var self = this;
        if (typeof number == 'number' && number >= 1) {
            self.$itemCount.addClass('hello');
            self.$cartPreviewPopover.removeClass('hide');
        }
        if ( first ) {
            self.$itemCount.text(number);
        } else {
            self.$itemCount.addClass('updating').text(number);
        }
        setTimeout(function() {
            self.$itemCount.removeClass('updating');
        }, 800);
    };

    CartPreview.prototype.fillFields = function(productCells) {
        for (var i = 0; i < this.dataItems.length; i++) {
            if (typeof this.dataItems[i] !== "undefined") {
                this.addItem(this.dataItems[i]);
            }
        }

        var cartTotal = parseFloat($.cookie('cart_total'),10);
        var priceLabel = '';
        if(this.data['foundShowLabelPix']) {
            priceLabel = ' no Pix';
        }
        this.$subtotalPrice.text(_formatMoney(this.data.subtotal) + priceLabel);

        if (this.$element.find('[data-type=item]').first().children('.cart-preview-item').attr('data-sku') == '') {
            this.$element.find('[data-type=item]').first().remove();
        }

        while (this.$element.find('[data-type=item]').length > productCells) {
            this.$element.find('[data-type=item]').first().remove();
        }
    };

    CartPreview.prototype.addItem = function(data) {
        var newItem = this.$element.find('[data-type=item]').last().clone();
        var special_price = _formatMoney(data.special_price);

        this.resetItem(newItem);

        newItem.find('[data-field=image]').attr('src', data.image);
        newItem.find('[data-field=name]').text(data.name);
        newItem.find('[data-field=size]').text(data.size);
        newItem.find('[data-field=color]').text(data.color);
        newItem.find('[data-sku]').attr("data-sku", data.sku);
        newItem.find('[data-field=special_price]').text(special_price).parent().toggleClass('is-active', special_price !== null);
        
        const installmentText = data.highlight_quantity + ' x ' + _formatMoney(data.highlight_value);
        newItem.find('[data-field=installment]').text(installmentText);
        
        const priceText = data.show_label_pix ? _formatMoney(data.unit_price) + ' no Pix' : _formatMoney(data.unit_price)
        newItem.find('[data-field=price]').text(priceText);
        

        this.$element.find('[data-type=item]').last().after(newItem);

        $(document).trigger('dft.cart-preview.addItem');
    };

    CartPreview.prototype.resetItem = function(itemEl) {
        itemEl.find('[data-field]').text('');
    };

    CartPreview.prototype.resetItens = function() {
        while (this.$element.find('[data-type=item]').length > 1) {
            this.$element.find('[data-type=item]')[0].remove();
        }
        this.$element.find('[data-type=item]').find('[data-field]').text('');
    };

    CartPreview.prototype.removeItem = function(el) {
        var sku = $(el).data('sku');
        window.location.href = "/cart/remove/?sku=" + sku;
    };

    var _formatMoney = function(value) {
        if (typeof value === 'number') {
            return value.toFixed(2).replace('.', ',');
        } else {
            return null;
        }
    };

    $.fn.cartPreview = function(option, args) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data('dft.cart-preview');
            var options = $.extend({}, CartPreview.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.cart-preview', (data = new CartPreview(this, options)));
            if (typeof option == 'string') data[option](args);
        });
    };

    $(document).on('click', '.touch.tablet .cart-preview [data-toggle=popover]', function(e) {
        if (!$(e.currentTarget).closest('.cart-preview').is('.is-open')) {
            e.preventDefault();
        }
        $(e.currentTarget).closest('.cart-preview').cartPreview('open');
    });

    $(document).on('click', '.touch.tablet .cart-preview .cart-preview-link', function(e) {
        if (!$(e.currentTarget).closest('.cart-preview').is('.is-open')) {
            e.preventDefault();
        }
        $(e.currentTarget).closest('.cart-preview').cartPreview('open');
    });

    $(document).on('mouseover', '.desktop .cart-preview', function(e) {
        $(e.currentTarget).cartPreview('open');
    });

    $(document).on('click', '[data-action=remove]', function(e) {
        e.preventDefault();
        var elTrigger = $(e.currentTarget);
        elTrigger.closest('.cart-preview').cartPreview('removeItem', elTrigger.parents('[data-sku]'));
    });

    $(document).on('dft.add-to-cart-form.success', function() {
        $(".cart-preview").cartPreview("setReload");
        $(".cart-preview").cartPreview("addItemCount", 1);
    });

    $(document).ready(function() {
        var url = window.secureUrl || '';

        url += '/cart/index/indexJson/?callback=?&freight=true';

        if (window.dft.urlParam('tp')) {
            url += '&tp=' + window.dft.urlParam('tp');
        }

        $('.cart-preview').cartPreview({count: $.cookie('cart_count'), jsonUrl: url});
    });

})(jQuery);
